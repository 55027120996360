
import './App.css';

import Home from './pages/home';

import ErrorPage from './pages/error'

import ExampleCrafMyPDF from './pages/exempleCraftMyPDF'

import {
  BrowserRouter ,
  Routes,
  Route
} from "react-router-dom";


function App() {
  return (
    <BrowserRouter>
    <Routes>

        <Route path="pdf" element={<ExampleCrafMyPDF />}/>
        <Route index element={<Home />} />
        <Route path='*' element={<ErrorPage />} />

    </Routes>
  </BrowserRouter>
  )
}



export default App;
