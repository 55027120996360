import { useEffect, useState } from "react"
import {useLocation} from "react-router-dom";
const axios = require("axios").default;



function ExampleCrafMyPDF() {

	const [isLoaded, setIsLoaded] = useState(false);
	const [pdfData, setPdfData] = useState(null);
	const [pimData, setPimData] = useState(null);
	const [isAdmin, setIsAdmin] = useState(0);
	const search = useLocation().search;
	


	useEffect(() => {
			
			const currentIds = new URLSearchParams(search).get('ids');
			const currentToken = new URLSearchParams(search).get('token');

			setIsAdmin(new URLSearchParams(search).get('isAdmin'));

			const currenLocale = new URLSearchParams(search).get('locale');
			const idTemplate = new URLSearchParams(search).get('template');
			const today =  new Date().toLocaleString("fr-FR");
			document.title = "Template Foo"

			let dataUrl = "/api/getDataPDFB2C";
			let pdfGenerateUrl = "/api/generatePDF";
			if (process.env.NODE_ENV === "development") {
				dataUrl = "http://localhost:4000/api/getDataPDFB2C";
				pdfGenerateUrl = "http://localhost:4000/api/generatePDF";
			}

			
			axios.get(dataUrl,{params: {token: encodeURIComponent(currentToken), ids:currentIds, locale: currenLocale,template:idTemplate}}).then(resp => {	
				if (resp.status === 200) {	
					
					const isSomeDataIncomplete = resp.data.dataIncomplete
					setPimData(resp.data.payloadCraftList[0].data)
					axios.post(pdfGenerateUrl,resp.data,{params: {token: encodeURIComponent(currentToken), ids:currentIds, locale: currenLocale,template:idTemplate}}).then((respCraft) =>{
						if (respCraft.status === 200) {
							let result = respCraft.data
							result["dataIncomplete"] = isSomeDataIncomplete
							setPdfData(result)

						} else {
							console.log("error",resp.data)
						}
						setIsLoaded(true)

					}).catch(error => {
						console.log(error)
						setIsLoaded(true)
					})
				} else {
					setIsLoaded(true)
					console.log("error",resp.data)
				}
			}).catch(error => {
				console.log(error)
				setIsLoaded(true)
			})
			
			
 		
	  }, [])

	
	
	let dataPdf;

	let refJsonContent
	if (pimData) {
		refJsonContent = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(pimData,null,4))}`;
	}

	if (isLoaded) {
		if (pdfData != null) {
			let listStructData =  []


      		const dataLength =pdfData.pdfList.length
			for (let index = 0; index < dataLength; index++) {
				const element = pdfData.pdfList[index];
				if (index === dataLength -1) {
					console.log("last")
				}
				const urlWithoutQueryParams =element.file.split("?")[0]
				const urlPathElements = urlWithoutQueryParams.split("/")
				const filename =  urlPathElements[urlPathElements.length -1]
				listStructData.push({file:element.file,filename:filename} )
				
			}
			dataPdf = <div style={styleExemplePage.pdfLinkContainer} >{listStructData.map((link) => <a href={link.file} target="_blank" rel="noreferrer">{link.filename}</a>)}</div>
		} else {
				dataPdf = <div className='pdfLoader'> Erreur dans la récupération des données ou dans la generation des PDF</div>
		}
	} else {
			dataPdf =<div className='pdfLoader'>Récupération des données...<br/>Veuillez Patienter</div>
	}

	return (
		<div style={{margin: 0}}>
			<div style={styleExemplePage.pdfLinkContainer}>
      			<h2>Mes PDF</h2>
    		</div>

			{ pimData != null && isAdmin === "1" &&

			<div style={styleExemplePage.pdfLinkContainer}>
				<a  href={refJsonContent} download={"dataPim.json"} >
					
						Contenu de l'onglet DATA de Craft My PDF
					
				</a>
			</div>

}
			<div style={styleExemplePage.pdfLinkContainer}>
				{dataPdf}
			</div>
			{isLoaded && pdfData != null && pdfData.dataIncomplete === true &&
				<div style={styleExemplePage.pdfLinkContainer}>
					<p style={styleExemplePage.textError} ><i>Certains des séjours semblent incompatibles ou incomplets pour le format de fiche attendue. Etes-vous sûr d'avoir par exemple un village et des expériences associées ?</i></p>
				</div>
			}
			


			
			
		</div>
		
	)
}

const styleExemplePage = {
	pdfLinkContainer : {
		display: "flex",
		flexDirection: "column",
		textAlign:"center",
		alignItems: "center"
	},
	centerContainer: {
		display: "flex",
		justifyContent: "center"
	},
	textError:{
		"text-align":"center",
		"padding":20
	},
	dataPimBtnContainer: {
		color:"white",
		backgroundColor:"blue",
		width:300,
		paddingBottom: 15,
		paddingTop: 15,
		paddingRight:25,
		paddingLeft:25,
		margin:5,
		textDecoration:"none",
		borderRadius:5
		
	}
}


export default ExampleCrafMyPDF;